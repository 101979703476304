const Config = require('~config/default.env').default;
const merge = require('lodash.merge');
import axios from 'axios';
import { removeFalsyParams } from '~global/helpers';
import { isWeb } from '~global/screens';

const limitProduct = isWeb ? 20 : 10;

export function getFeaturedBrands() {
  return axios.get(`${Config.MS_CATALOG_API_URL}/v3/brands`, {
    params: {
      filter: { is_featured_lulla: true },
      fields: 'slug,logo,name,featured_text_lulla',
      skip: 0,
      limit: 15,
      sort: '-featured_created_at_lulla',
    },
  });
}

export function getFeaturedBrandsV2({
  slug,
  type,
  lillaCategoryType,
  keyword,
}) {
  try {
    let defaultParams = {
      fields: '_id+name+slug+logo+featured_text',

      filter: JSON.stringify({
        'categories.slug': slug,
        type: type,
        ...(keyword && {
          keyword: keyword,
          elasticSearch: true,
        }),
      }),
    };

    if (['group', 'group-child'].includes(lillaCategoryType)) {
      defaultParams = {
        fields: '_id+name+slug+logo+featured_text',
        filter: {
          slug,
          type,
          lilla_category_type: lillaCategoryType,
          is_new_lilla_category: true,
        },
      };
    }

    return axios.get(
      `${Config.MS_CATALOG_API_URL}/v3/brands/distinct/products`,
      {
        params: defaultParams,
      },
    );
  } catch (error) {
    console.error('An error occurred:', error.message);
  }
}

export function getBrandsLetters() {
  return axios.get(`${Config.MS_CATALOG_API_URL}/v3/brands/letters`, {
    params: {
      fields: 'name, logo, slug, id',
      limit: 100,
      sort: 'name',
    },
  });
}

export function getBrand(brandId) {
  return axios.get(`${Config.MS_CATALOG_API_URL}/v3/brands/${brandId}`, {
    params: {
      fields:
        'description, short_description, logo, name, total_products, total_reviews, slug, awards, video',
      limit: 1,
      skip: 0,
    },
  });
}

export function getTotalCategories(brandId) {
  return axios.get(
    `${Config.MS_CATALOG_API_URL}/v3/categories/distinct/products`,
    {
      params: {
        filter: { 'brand.id': brandId, is_children: true },
      },
    },
  );
}

export function getVouchers(brandId) {
  return axios.get(`${Config.MS_CART_API_URL}/brand/${brandId}/vouchers`, {
    params: {
      fields:
        '_id name discount_type free_products_type voucher_info image to_date from_date code rules',
      sort: '-created_at',
    },
  });
}

export function getBanners(brandId) {
  return axios.get(`${Config.MS_CATALOG_API_URL}/v3/brand-extra`, {
    params: {
      filter: { brand_id: brandId },
      limit: 1,
      sort: '-update_at',
      fields: '_id banners brand_highlights is_active_in_lulla',
    },
  });
}

export function getTotalProducts({ id, params }) {
  const defaultParams = {
    filter: { 'brand.id': id },
    limit: limitProduct,
    skip: 0,
  };
  const finalParams = removeFalsyParams({ ...params });
  const mergeParams = merge(defaultParams, finalParams);

  return axios.get(`${Config.MS_CATALOG_API_URL}/search/count`, {
    params: mergeParams,
  });
}

export function getAllProducts({ id, params }) {
  const defaultParams = {
    filter: { 'brand.id': id },
    sort: '-three_month_total_orders_lilla _id',
  };
  const finalParams = removeFalsyParams({ ...params });
  const mergeParams = merge(defaultParams, finalParams);

  return axios.get(`${Config.MS_CATALOG_API_URL}/search`, {
    params: mergeParams,
  });
}

export function getBestSellerProducts(brandId) {
  return axios.get(`${Config.MS_CATALOG_API_URL}/search`, {
    params: {
      filter: { 'brand.id': brandId },
      limit: limitProduct,
      skip: 0,
      sort: '-three_month_total_orders_lilla _id',
    },
  });
}

export function getWhatsNewProducts(brandId) {
  return axios.get(`${Config.MS_CATALOG_API_URL}/v3/products`, {
    params: {
      sort: '-created_at',
      skip: 0,
      limit: limitProduct,
      filter: { 'brand.id': brandId },
    },
  });
}
